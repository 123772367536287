// NO JQUERY

var outletMenu = (function () {
	var goBackBtns = [].slice.call(document.querySelectorAll(".menuMobileBack"));
    	var mobileMenuTrigger = document.querySelector(".mobileMenuTrigger");
    	var topMenu = document.querySelector(".menuMain");
    	var menuBar = document.querySelector(".menuContain");
    	var topHeader = document.querySelector(".topHeader");
    	var menuCategories = [].slice.call(document.querySelectorAll(".menuMainCat"));
    	var allSubCats = [].slice.call(document.querySelectorAll(".menuSubMenu"));
    	var currentScreenWidth;

    	var resetMenus = function () {
        	allSubCats.forEach(function(el){
    			el.classList.remove('showDropDown');
    			el.classList.remove('animate');
    		});
        	menuCategories.forEach(function(el){	
        		el.classList.remove('active');
        		el.setAttribute('aria-expanded', 'false');    
        	});
    	},
    	showHideCat = function(e){
    		e.preventDefault();
    		var el = e.currentTarget;
		var submenu = el.nextElementSibling;
    		if(el.classList.contains("active")){
    			hideCatDropDown();
    		}else{
    			showCatDropDown(el, submenu);
    		}
    	},
    	showCatDropDown = function (trigger, subcat) {
        	resetMenus();
		trigger.classList.add('active');
		trigger.setAttribute('aria-expanded', 'true');
		subcat.classList.add("showDropDown");
		
		setTimeout(function(){
			subcat.classList.add('animate');
		}, 100);
	},
    	hideCatDropDown = function(){
		allSubCats.forEach(function(el){
			el.classList.remove('showDropDown');
			el.classList.remove('animate');
		});
		menuCategories.forEach(function(el){
			el.classList.remove("active");
			el.setAttribute('aria-expanded', 'false');
		});
	},
	stickyMenu = function() {
    		if (window.pageYOffset >= topHeader.offsetHeight) menuBar.classList.add("sticky-header");
    		else menuBar.classList.remove("sticky-header");
	},
    	displayMenu = function () {
    		topMenu.classList.remove("hide");
    		document.body.style.overflow = "hidden";
    		setTimeout(function(){
	        	topMenu.classList.add("menuopen");
	        	mobileMenuTrigger.setAttribute("aria-expanded", "true");
	        	mobileMenuTrigger.querySelector("svg").classList.remove("rotate180");
	        }, 200);
    	},
    	hideMenu = function () {
        	topMenu.classList.remove("menuopen");
        	topMenu.classList.add("hide");
        	document.body.removeAttribute("style");
        	mobileMenuTrigger.setAttribute("aria-expanded", "false");
        	mobileMenuTrigger.querySelector("svg").classList.add("rotate180");
        	resetMobileMenu();
    	},
    	resetMobileMenu = function(){
    		if(document.querySelector(".subview")){
        		document.querySelector(".subview").classList.remove("subview");
        	}
    		if(document.querySelector(".subviewopen")){
        		document.querySelector(".subviewopen").querySelector("a").setAttribute("aria-expanded", "false");
        		document.querySelector(".subviewopen").classList.remove("subviewopen");
        	}	
        	if(document.querySelector(".animate-in")){
        		document.querySelector(".animate-in").classList.remove("animate-in");
        	}
        	if(document.querySelector(".animate-out")){
        		document.querySelector(".animate-out").classList.remove("animate-out");
        	}
    	},
    	/*showHideMenu = function () {
        	if (window.innerWidth >= 800) {
        		displayMenu();
        	}else{
        		hideMenu(); 
        	}
    	},*/
    	showMobileSecondTier = function (e) {
        	var submenuTarget = e.currentTarget;
        	e.preventDefault();
    		e.stopPropagation();
    		submenuTarget.nextElementSibling.classList.remove("animate-out");
    		submenuTarget.nextElementSibling.classList.add("animate-in");
    		topMenu.classList.add("subview");

    		setTimeout(function(){
    			submenuTarget.setAttribute("aria-expanded", "true");
    			submenuTarget.parentNode.classList.add("subviewopen");
    			submenuTarget.nextElementSibling.querySelector(".shopAll").focus();
    		}, 200);
    	},
    	hideMobileSecondTier = function(e){
    		e.preventDefault();
    		var el = e.currentTarget;
    		el.parentNode.parentNode.classList.remove("animate-in");
    		el.parentNode.parentNode.classList.add("animate-out");
    		el.parentNode.parentNode.parentNode.classList.remove("subviewopen");
    		    			
    		setTimeout(function(){
    			topMenu.classList.remove("subview");
    			el.parentNode.parentNode.previousElementSibling.setAttribute("aria-expanded", "false");
    			el.parentNode.parentNode.previousElementSibling.focus();
    		}, 200);
    	},
    	addRemoveListeners = function () {
    		if(window.innerWidth >= 800){
    			menuCategories.forEach(function (el) {
            			el.removeEventListener("click", showMobileSecondTier);
            			el.addEventListener("click", showHideCat);
            		});
    		}else{
    			menuCategories.forEach(function (el) {
                    		el.removeEventListener("click", showHideCat);
                    		el.addEventListener("click", showMobileSecondTier);
            		});
    		}
    	},
	mobileTweaks = function(){
		if(currentScreenWidth !== window.innerWidth && topMenu.classList.contains("menuopen")){
			mobileMenuTrigger.click();
		}
		addRemoveListeners();
	},
	
	init = function(){
		currentScreenWidth = window.innerWidth;
		
		addRemoveListeners();
		
		mobileMenuTrigger.addEventListener("click", function(e){
			e.preventDefault();
			if(topMenu.classList.contains("menuopen")) hideMenu();
			else displayMenu(); 
		});
			
		goBackBtns.forEach(function(el){
			el.addEventListener('click', hideMobileSecondTier);
		});
		
		window.onscroll = function() { stickyMenu(); };
		window.addEventListener("resize", mobileTweaks);
	};
	
	return{
		Init: init
	};
})();

(function(){
	outletMenu.Init();
})();