//HEADER
var outletHeader = (function(){
	var searchBox = document.querySelectorAll(".headerSearch"); //need to return a nodelist to work
    	var menuBar = document.querySelectorAll(".menuContain");
    	var outletLogo = document.querySelector(".headerLogoSearch").querySelectorAll("a");
    	var keys = { ESC: 27, TAB: 9, RETURN: 13, DOWN: 40, CAPS: 20 };
    	
	var init = function(){
		if(document.getElementById("search")){
		    	document.getElementById("search").addEventListener("keyup", function (e) {			    	
		       	if(e.keyCode == keys.RETURN) {
		            		search(document.getElementById("search").value);
		        	}else if ((e.keyCode < 35 || e.keyCode > 40) && e.keyCode !== keys.ESC && e.keyCode !== keys.CAPS) {
					// extreme key code stuff needed to get this to play nice with voiceover/safari
					if(document.querySelector(".searchError")){
				    		document.querySelector(".searchError").parentNode.removeChild(document.querySelector(".searchError"));
				    	}
		   		}
		    	});
			document.getElementById('logo_search').addEventListener("click", function (e) {
				e.preventDefault();
		        	search(document.getElementById("search").value);
		    	});
		}
		
		//if(document.getElementById("headerAccountWrapper")){
		//	accountHeader.Init();
		//}
		accountHeader.LoadUserLinks();
		
		if(document.querySelector(".skip")){
			document.querySelector(".skip").addEventListener("click", skipToMain);
		}

		moveSearchBox();
		window.addEventListener("resize", moveSearchBox);
	},
	skipToMain = function(e){
		e.preventDefault();
		var el = e.currentTarget;
            	var skipTo = "#" + el.href.split('#')[1];
            	document.querySelector(skipTo).setAttribute('tabindex', -1);
             	document.querySelector(skipTo).addEventListener('blur', function () {
                		this.removeAttribute('tabindex');
            	});
            	document.querySelector(skipTo).addEventListener('focusout', function () {
                		this.removeAttribute('tabindex');
            	});
            	document.querySelector(skipTo).focus();
	},
	accountHeader = (function(){
		var accountLink,
		
		init = function(){
			accountLink = document.getElementById("accountLink");
			
			accountLink.addEventListener("click", showHideAccount);
			keyboardEvents();
		},
		loadUserLinks = function(){
			fetch('/Header/UserSpecificLinks', { headers: { 'X-Requested-With': 'fetch' }}).then(function(response){
				return  (response.ok) ? response.text() : '';
			}).then(function(html){
				if(html.length < 1) { return; }
				document.getElementById('userLinks').innerHTML = (html);
				init();
			});
		},			
		showHideAccount = function(e){
			e.preventDefault();
			if(document.getElementById("accountDropDown").classList.contains("hide")){
				show();
			}else{
				hide();
			}
		},
		show = function() {
			document.getElementById("accountDropDown").classList.remove("hide");
			accountLink.setAttribute("aria-expanded", "true");
			accountLink.classList.add("active");
			document.addEventListener("keydown", escapeAccount);
			document.addEventListener("click", closeClickOutside);
		},
		hide = function(){
			document.getElementById("accountDropDown").classList.add("hide");
			accountLink.setAttribute("aria-expanded", "false");
			accountLink.classList.remove("active");
			document.removeEventListener("keydown", escapeAccount);
			document.removeEventListener("click", closeClickOutside);
		},
		escapeAccount = function(e){
	    		if(e.keyCode === 27){ // allow user to close on escape
				hide();
				accountLink.focus();
	    		}
		},
		closeClickOutside = function(e){
			var specifiedElement = document.getElementById('headerAccountWrapper');
			var isClickInside = specifiedElement.contains(e.target);
			
			if (!isClickInside) {
				hide();
			}
		},
		keyboardEvents = function(){
			var accountLinks = document.getElementById("accountDropDown").querySelectorAll("a");
			var lastLink = accountLinks[accountLinks.length - 1];
			lastLink.addEventListener("keydown", function(e){
				if(e.keyCode == 9){
					e.preventDefault();
					e.stopPropagation();
					hide();
					document.getElementById("headerAccountWrapper").nextElementSibling.querySelector("a").focus();
				}
			});
		};
		return{
			Init: init,
			LoadUserLinks: loadUserLinks
		};
	})(),
	search = function(searchby) {
	    	searchby = searchby.replace(/^\s+|\s+$/g, '');
	    	searchby = searchby.replace(" ", "_");
	    	var searchError = '<p class="searchError textDkRed text16" role="alert" aria-atomic="true">Error: Enter content to search.</p>';

	    	if (searchby != "") {
	        	//window.location = "/search/" + searchby;
	        	window.location = "/main/search/" + searchby;
	    	}else{
	    		//add in error due to no content
	    		document.getElementById("search").parentNode.insertAdjacentHTML("afterend", searchError);
	    	}
	},
	moveSearchBox = function(){
		//only move the search box if it isn't in the place it should be
		if(window.innerWidth < 600 && !menuBar[0].parentNode.previousElementSibling.classList.contains("headerSearch")){
			// search goes after all links
    			menuBar[0].parentNode.insertBefore(searchBox[0], menuBar[0]);
		}else if(window.innerWidth >= 600 && !document.querySelector(".headerLogoSearch").querySelector("a").nextElementSibling){
			//search goes after logo
			outletLogo[0].parentNode.insertBefore(searchBox[0], outletLogo[0].nextSibling);
		}
	};
	
	return{
		Init: init
	};
})();

//FOOTER
var outletFooter = (function(){
	var footerAccordions,
	
	init = function(){
		footerAccordions = new getAccordions4i();
	       footerAccordions.init(document.querySelector("footer"));
	       openCloseAccordions();
	       window.addEventListener("resize", openCloseAccordions);
	},
	openCloseAccordions = function(){		
		if(window.innerWidth < 600){
			//if mobile then collapse
			footerAccordions.close(document.getElementById("quickLinksSection"));
			footerAccordions.close(document.getElementById("mediaSection"));
			footerAccordions.close(document.getElementById("servicesSection"));
		}else{
			//open all
			footerAccordions.openAll(document.querySelector("footer"));
		}
	};
	return{
		Init: init
	};
})();


(function(){
	//var loadChat = new chat4i();
	outletHeader.Init();
	//loadChat.init();
	
	//if accordions are present in footer
	if(document.getElementById("mediaSection")){
		outletFooter.Init();
	}
})();